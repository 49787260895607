.Contact {
  text-align: center;
  overflow-x: hidden;
  max-width: 100vw;
}

.Contact-header {
  position:relative;
  background-color: white;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}


.footer {
  position:relative;
  bottom:0;
  left:0;
  max-width:100vw;
  height: 100px;
  background-color: black;
  text-align: center;
  overflow-x: hidden;
}

.footer p {
  color: white;
}

/* Add flex styles to create a sticky footer */
.Contact-content {
  flex: 1; /* Make the content section take the remaining space and push the footer to the bottom */
}
  
