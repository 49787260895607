.Home {
    text-align: center;
    margin: 0;
    max-width: 100vw;
    overflow-x: hidden;
  }
  
  .Home-logo {
    height: 40vmin;
    pointer-events: none;
    margin: 0;
    max-width: 100vw;
  }
  
  .Home-header {
    background-color: white;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    margin: 0;
    overflow-x: hidden;
  }
  
  .Home-link {
    color: #61dafb;
  }

#Button1 {
    background-color: white;
    font-size: 25px;
    padding: 15px;
    border-color: black;
    border-radius: 15px;
    color: black;
    font-weight: bold;
}

#Button10 {
    background-color: rgba(255, 255, 255, 0); /* Completely transparent */
    font-size: 25px;
    padding: 15px;
    border-color: white;
    border-radius: 15px;
    color: white;
    font-weight: bold;
}

  
.footer {
  position:absolute;
  bottom:0;
  left:0;
  max-width: 100vw;
  height: 150px;
  background-color: black;
  text-align: center;
  margin: 0;
  overflow-x: hidden;
}

.footer p {
  color: white;
}
