.About {
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  .About-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .About-header {
    text-align: center;
    position:relative;
    background-color: white;
    max-width: 100vw;
    display: fixed;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  
  .footer {
    position:relative;
    bottom:0;
    left:0;
    max-width:100vw;
    height: 100px;
    background-color: black;
    text-align: center;
    overflow-x: hidden;
  }
  
  .footer p {
    color: white;
  }
