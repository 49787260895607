.Portfolio {
    text-align: center;
    overflow-x: hidden;
  }
  
  .Portfolio-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Portfolio-header {
    background-color: white;
    overflow-x: hidden;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  

  .footer {
    position:relative;
    overflow-x: hidden;
    bottom:0;
    left:0;
    width:100vw;
    height: 150px;
    background-color: black;
    text-align: center;
  }
  
  .footer p {
    color: white;
  }
  
  body {
    overflow-x: hidden;
    width: 100vw;
  }
  
body {
  overflow-x: hidden;
  width: 100vw;
}